import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "item" ]

  connect() {
  }

  item_collapse() {
    const element = this.itemTarget
    if ( element.classList.contains("expanded") ) {
      element.classList.remove("expanded")
    } else {
      element.classList.add("expanded")
    }
  }

  toggle_navbar() {
    const element = this.itemTarget
    var navEl = element.parentNode
    while (navEl.id != "navbar") {
      navEl = navEl.parentNode
    }
    if ( navEl.classList.contains("expanded") ) {
      navEl.classList.remove("expanded")
    } else {
      navEl.classList.add("expanded")
    }
  }
}
