import { Controller } from "stimulus"

export default class extends Controller {
  static values = { language: String }

  // set language to browser language if user hasn't set it manually
  connect() {
    const languageKey = "l"
    var browserLanguage = window.navigator.userLanguage || window.navigator.language;
    const urlParams = new URLSearchParams(window.location.search);
    const currentLanguage = urlParams.get(languageKey);
    var languageCode = browserLanguage.split("-")[0];
    if ( !currentLanguage && languageCode ) {
      window.location.href = window.location.pathname+"?"+languageKey+"="+languageCode;
    } else {
      console.log("browser language matches current");
    }
  }

}
