import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "country", "states" ]

  connect() {
  }

  country_select() {
    const element = this.countryTarget
    const countryCode = element.value
    const stateEl = this.statesTarget
    if ( countryCode == "US" ) {
      stateEl.classList.add("show")
    } else {
      stateEl.classList.remove("show")
    }
  }
}
