import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    console.log("certificates connected")
  }

  showTranslation() {
    let translation = document.getElementById("certificate-translation")
    let english = document.getElementById("certificate-english")
    english.classList.remove("show")
    translation.classList.add("show")
    let translationBtn = document.getElementById("translationBtn")
    let englishBtn = document.getElementById("englishBtn")
    translationBtn.classList.add("active")
    englishBtn.classList.remove("active")
  }

  showEnglish() {
    let translation = document.getElementById("certificate-translation")
    var english = document.getElementById("certificate-english")
    translation.classList.remove("show")
    english.classList.add("show")
    let translationBtn = document.getElementById("translationBtn")
    let englishBtn = document.getElementById("englishBtn")
    translationBtn.classList.remove("active")
    englishBtn.classList.add("active")
  }
}
