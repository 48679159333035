// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
import "../stylesheets/application"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

Rails.start()
Turbolinks.start()
ActiveStorage.start()

function getEnv(name) {
  const el = document.querySelector(`meta[name=${name}]`)
  if (el instanceof HTMLMetaElement) {
    return el.content
  }
}

const sentryDsn = getEnv("SENTRY_DSN_JS")
if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    environment: getEnv("SENTRY_CURRENT_ENV"),
    release: getEnv("HEROKU_SLUG_COMMIT"),
  })
}
